@import '../../../sass/variables';

.information-template {
	display: flex;
	flex-direction: row;
	margin-bottom: 90px;

	.information-wrapper {
		text-align: center;
		padding: 0 15px;

		> :not(:first-of-type) {
			margin-top: 45px;
		}

		.information {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $primaryColor;

			.title {
				text-transform: uppercase;
				font-weight: 100;
				font-size: 30px;
			}

			.line {
				width: 140px;
				height: 1px;
				background-color: $primaryColor;
				margin-top: 20px;
				margin-bottom: 30px;
			}

			.content {
				position: relative;

				.quote-img {
					position: absolute;
					opacity: 0.03;
					width: 70px;
					left: 50%;
					top: -28px;
					transform: translateX(-50%);
				}

				p {
					line-height: 26px;

					&:not(:last-of-type) {
						margin-bottom: 30px;
					}
				}
			}
		}

		.img {
			width: 100%;
		}
	}

	$padding: 40px;
	.table-of-contents-wrapper {
		width: 320px;

		.toc-container {
			background-color: $lightSecondaryColor;
			border-radius: 10px;
			padding: 40px 0;
			width: 320px;
			position: sticky;
			top: 20px;

			.title {
				background-color: $primaryColor;
				color: white;
				font-weight: 400;
				font-size: 18px;
				padding: 15px;
				padding-left: $padding;
				text-transform: capitalize;
			}

			ul {
				padding-left: $padding;
				margin-top: 25px;

				li {
					&:not(:last-of-type) {
						margin-bottom: 30px;
					}

					button {
						color: $primaryColor;
						font-weight: 100;
						font-size: 16px;
						letter-spacing: 1px;
						cursor: pointer;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $screen-md) {
	.information-template {
		.information-wrapper {
			margin-right: 100px;
			text-align: initial;
			padding: initial;

			> :not(:first-of-type) {
				margin-top: 100px;
			}

			.information {
				.title {
					font-size: 35px;
				}

				.line {
					margin-bottom: 50px;
				}

				.content {
					.quote-img {
						top: -35px;
						transform: inherit;
						left: initial;
					}
				}
			}
		}
	}
}
