$primaryColor: #571c45;

$secondaryColor: #931941;
$lightSecondaryColor: rgba(
	$color: $secondaryColor,
	$alpha: 0.1
);

$borderRadius: 7px;

$pageWidth: 1300px;

@mixin gradient {
	background: linear-gradient(
		to right,
		hsla(324, 26%, 95%, 1) 0%,
		hsla(326, 17%, 85%, 1) 100%
	);
}

// Repsonsive

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 992px;

// Tablets and small desktops
$screen-lg: 1075px;

// Large tablets and desktops
$screen-xl: 1075px;
