@import '../../sass/variables';

.payment-complete {
	color: $primaryColor;
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	font-weight: 300;

	.checkmark {
		width: 70px;
		flex-basis: 70px;
		height: 70px;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba($primaryColor, 0.8);
		box-shadow: 0px 0px 20px 2px rgba($primaryColor, 0.2);
		color: white;
		font-size: 40px;

		.bg-logo {
			top: 200px;
		}
	}

	h2.title {
		font-size: 34px;
		margin-top: 30px;
		margin-bottom: 50px;
	}

	div.thanks {
		font-size: 23px;
		line-height: 40px;

		span {
			font-weight: 600;
			color: rgba($primaryColor, 0.7);
		}
	}

	p.content {
		margin-top: 20px;
		font-size: 20px;
	}

	.line {
		margin-top: 75px;
		margin-bottom: 40px;
		width: 100%;
		height: 1px;
		background-color: rgba($primaryColor, 0.2);
	}

	.go-back {
		a {
			color: inherit;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
