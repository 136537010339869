@import '../../sass/variables';

.quote-container {
	background-color: $primaryColor;
	color: white;
	margin: 70px 0;

	h3 {
		text-align: center;
		font-weight: 100;
		font-size: 20px;
		line-height: 38px;
		padding: 30px 0;
	}
}

@media only screen and (min-width: $screen-md) {
	.quote-container {
		margin: 140px 0;

		h3 {
			font-size: 33px;
			line-height: 60px;
			padding: 60px 0;
		}
	}
}
