@import '../../../sass/variables';

.staff-page {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.staff-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.staff {
			flex-basis: 28%;
			height: 450px;
			margin-bottom: 70px;
			border-radius: 30px;
			overflow: hidden;
			position: relative;
			box-shadow: 0px 0px 20px 1px rgba(87, 28, 69, 0.3);

			img {
				width: 100%;
			}

			.text {
				position: absolute;
				bottom: 0;
				background-color: $primaryColor;
				color: white;
				padding: 30px;
				width: 100%;

				h3 {
					margin-bottom: 11px;
					font-size: 27px;
					font-weight: 100;
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.staff-page .staff {
		flex-basis: 32% !important;
	}
}

@media only screen and (max-width: 950px) {
	.staff-page .staff {
		flex-basis: 45% !important;
	}
}

@media only screen and (max-width: 670px) {
	.staff-page .staff {
		flex-basis: 47% !important;
	}
}

@media only screen and (max-width: 560px) {
	.staff-page .staff-container {
		justify-content: center !important;

		.staff {
			flex-basis: 90% !important;
		}
	}
}
