@import '../../sass/variables';

.collection-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&.definitions .collection {
		width: 290px;
		height: 100px;
		line-height: 25px;
	}

	.collection {
		text-decoration: none;
		color: $primaryColor;
		border: 1px solid $primaryColor;
		border-radius: 20px;
		width: 200px;
		height: 80px;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 15px 20px;
		background-color: white;
		border: 1px solid rgba($primaryColor, 0.1);
		box-shadow: 0px 0px 5px rgba($primaryColor, 0.1);
		transition: background-color 0.2s ease;

		&:hover {
			background-color: #f5eff2;
			box-shadow: 0px 0px 5px rgba($primaryColor, 0.2);
		}

		h4 {
			font-weight: 100;
			text-align: center;
			display: flex;
			flex-direction: column;
			font-size: 20px;

			span {
				font-size: 13px;
				margin-top: 6px;
			}
		}
	}
}

.section-container {
	&:not(:first-of-type) {
		margin-top: 70px;
	}

	.sub-title {
		text-align: center;
		margin-bottom: 10px;
		line-height: 25px;
		font-weight: 200;
		font-size: 23px;
		color: rgba($primaryColor, 0.6);

		span {
			width: 150px;
			height: 1px;
			background-color: rgba($primaryColor, 0.2);
			display: block;
			margin: auto;
			margin-top: 3px;
		}
	}
}

@media only screen and (max-width: 1105px) {
	.collection-container {
		&.definitions .collection {
			width: 250px;
		}
	}
}

@media only screen and (max-width: 975px) {
	.collection-container {
		&.definitions .collection {
			margin: 10px 10px;
			width: 230px;
		}
	}
}

@media only screen and (max-width: 835px) {
	.collection-container {
		&.definitions .collection {
			flex-basis: 45%;
		}
	}
}

@media only screen and (max-width: 800px) {
	.collection-container {
		.collection {
			margin: 10px 5px;
		}
	}
}

@media only screen and (max-width: 700px) and (min-width: 600px) {
	.collection-container {
		.collection {
			margin: 10px 30px;
		}
	}
}

@media only screen and (max-width: 480px) {
	.collection-container {
		justify-content: space-between;

		&.definitions .collection {
			flex-basis: 100%;
		}

		.collection {
			margin: 10px 0;
			flex-basis: 47%;
		}
	}
}

@media only screen and (max-width: 350px) {
	.collection-container {
		justify-content: space-between;
		.collection {
			margin: 10px 0;
			flex-basis: 100%;
		}
	}
}
