@import '../../../sass/variables';

.preparing-for-your-stay {
	color: $primaryColor;
	position: relative;

	.bg-logo {
		top: -130px;
	}

	.first-part {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.items-list-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		.title {
			margin-bottom: 30px;
		}

		.item-list {
			display: flex;
			font-weight: 200;

			ul {
				margin-right: 40px;

				li {
					display: flex;
					margin-bottom: 30px;
					line-height: 25px;

					span {
						margin-right: 10px;
						background-color: rgba($primaryColor, 0.1);
						border-radius: 100%;
						color: $primaryColor;
						text-align: center;
						height: 25px;
						flex: 0 0 25px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		img {
			box-shadow: 0px 0px 15px rgba($primaryColor, 0.5);
			border-radius: 20px;
		}
	}

	.other-info-container {
		margin-top: 50px;
		background-color: #faf5f7;
		padding: 70px 80px;
		padding-left: 50px;
		border-radius: 20px;
		border-bottom-right-radius: 250px;
		width: 50%;
		font-weight: 200;

		.title {
			margin-bottom: 20px;
		}

		li {
			border-left: 2px solid rgba($primaryColor, 0.1);
			padding-left: 15px;
			line-height: 25px;

			&:not(:last-of-type) {
				margin-bottom: 20px;
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	.preparing-for-your-stay .items-list-container .item-list {
		flex-direction: column;

		ul {
			margin-right: 0;
		}
	}
}

@media only screen and (max-width: 650px) {
	.preparing-for-your-stay .first-part {
		flex-direction: column;
		align-items: center;

		.other-info-container {
			width: 100%;
			margin-top: 30px;
		}
	}
}
