a {
	text-decoration: none;
}

ul,
ol {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

button {
	background: none;
	border: none;
	outline: none;
	font-family: inherit;
}

input,
textarea {
	font-family: inherit;
}
