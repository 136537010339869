@import '../../../sass/variables';

.about-page {
	color: #571c45;

	a:not(.button) {
		color: inherit;
		text-decoration: underline;
		font-size: 19px;

		&:hover {
			text-decoration: none;
		}
	}

	.about-section-container {
		position: relative;

		&:nth-child(1) {
			.about-section {
				margin-bottom: 120px;
			}

			.bg-logo {
				top: 200px;
			}
		}

		&:nth-child(3) {
			.bg-logo {
				top: -450px;
				right: -200px;
			}
		}

		&:nth-child(5) {
			.bg-logo {
				top: 200px;
			}
		}

		&:nth-child(8) {
			.about-section {
				margin-bottom: 100px;
			}

			.bg-logo {
				top: 100px;
				right: -200px;
			}
		}

		.about-section {
			display: flex;
			align-items: flex-start;
			margin-bottom: 150px;

			&.left {
				text-align: right;

				.text {
					margin-right: 50px;
				}

				img {
					order: 1;
				}

				.line {
					margin-left: auto;
					margin-right: 40px;
				}
			}

			&:not(.left) {
				.text {
					margin-left: 50px;
				}
			}

			.content {
				font-weight: 100;
				font-size: 17px;
				line-height: 30px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				p {
					margin-bottom: 20px;
					text-align: justify;
				}

				.button {
					margin-top: 10px;
				}
			}

			img {
				border-radius: 20px;
				box-shadow: 0px 0px 15px rgba($primaryColor, 0.5);
				width: 550px;
			}
		}

		.text {
			.title {
				text-transform: uppercase;
				font-weight: 100;
				font-size: 35px;
				margin-bottom: 30px;
			}

			.line {
				width: 140px;
				height: 1px;
				background-color: #571c45;
				margin-top: 20px;
				margin-bottom: 50px;
				margin-left: 40px;
			}
		}
	}

	.ethics-container {
		.bullet-point-section {
			display: flex;
			align-items: center;

			.bullet-point-container {
				&:first-of-type {
					margin-right: 100px;
				}

				&:last-of-type {
					background-color: rgb(250, 245, 247);
					padding: 130px 100px;
					border-top-left-radius: 350px;
					border-bottom-right-radius: 350px;
				}

				li {
					font-weight: 100;
					font-size: 17px;
					line-height: 30px;
					list-style-type: disc;
					margin-bottom: 20px;
					text-align: justify;
				}

				.title {
					font-size: 20px;
					line-height: 35px;
					font-weight: 100;
					margin-bottom: 30px;
				}
			}
		}
	}

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 100px;

		&.ethics {
			margin-bottom: 30px;
		}

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}
}

@media only screen and (max-width: 1250px) {
	.about-page {
		.about-section-container {
			.about-section {
				flex-direction: column;
				margin-bottom: 100px;

				.text {
					margin: 0 20px !important;
				}

				&.left {
					img {
						order: initial;
					}
				}

				&:not(.left) {
					align-items: flex-end;
				}

				img {
					margin-bottom: 60px;
				}
			}
		}

		.ethics-container {
			.bullet-point-section {
				.bullet-point-container {
					&:last-of-type {
						padding: 170px 50px 170px 90px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	.about-page {
		.ethics-container {
			.bullet-point-section {
				flex-direction: column;

				.bullet-point-container {
					&:first-of-type {
						margin-right: 0;
						margin: 0 30px;
					}

					&:last-of-type {
						padding: 130px 90px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.about-page {
		.about-section-container {
			.about-section {
				margin-bottom: 50px !important;

				img {
					width: 100%;
					order: 1 !important;
					margin-top: 20px;
				}
			}
		}

		.ethics-container {
			.bullet-point-section {
				.bullet-point-container {
					&:last-of-type {
						padding: 170px 50px 170px 90px;
					}
				}
			}
		}
	}
}
