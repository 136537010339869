@import '../../../sass/variables';

.services-page {
	color: $primaryColor;
	position: relative;

	a {
		color: inherit;
		text-decoration: underline;
		font-size: 19px;

		&:hover {
			text-decoration: none;
		}
	}

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.button {
		display: inline-block;
		margin-top: 30px;
		text-decoration: none;
	}

	.service {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 100px;
		font-weight: 200;
		line-height: 28px;
		position: relative;

		&:nth-child(even) {
			margin-top: 130px;
			// .img-container {
			// 	order: 1;
			// 	border-bottom-right-radius: 200px;
			// 	border-bottom-left-radius: 20px;
			// 	margin-right: 0;
			// 	margin-left: 50px;

			// 	.details-container {
			// 		justify-content: flex-start;
			// 	}
			// }
			// .detail {
			// 	text-align: left !important;
			// }

			.bg-logo {
				right: 0;
				top: 20px;
				left: initial;
			}
		}

		.bg-logo {
			top: 300px;
		}

		.image-panel {
			flex: 1;
			flex-basis: 10%;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.img-container {
				margin-right: 50px;
				display: flex;
				flex-direction: column;
				flex: 1;
				border-radius: 20px;
				border-bottom-left-radius: 200px;
				overflow: hidden;
				box-shadow: 0px 0px 15px rgba($primaryColor, 0.5);
				position: relative;

				img {
					width: 100%;
				}

				.details-container {
					display: flex;
					justify-content: flex-end;
					background-color: rgb(221, 207, 215);
					color: $primaryColor;
					padding: 20px 50px;

					.detail {
						display: flex;
						flex-direction: column;
						text-align: right;

						&:nth-child(2) {
							margin: 0 30px;
						}

						h4 {
							font-size: 20px;
							margin-bottom: 5px;
						}
						span {
							font-size: 16px;
							font-weight: 100;
						}
					}
				}
			}
		}

		.pointers {
			flex: 1;

			h3 {
				font-size: 34px;
				margin-bottom: 10px;

				span {
					font-weight: 100;
				}
			}
			p:last-of-type {
				margin-top: 20px;
			}

			ul {
				margin-top: 30px;

				li {
					display: flex;

					padding: 7px 0;
					line-height: 30px;

					.bullet-point {
						margin-right: 10px;
						margin-top: 7px;
						background-color: rgba($primaryColor, 0.1);
						border-radius: 100%;
						color: $primaryColor;
						text-align: center;
						height: 17px;
						flex: 0 0 17px;
					}
				}
			}
		}
		.content {
			display: flex;
			align-items: center;
			background-color: #faf6f8;
			padding: 40px;
			border-radius: 50px;
			margin-top: 30px;

			.about-image {
				display: flex;
				flex: 1.3;

				img {
					border-radius: 30px;
					box-shadow: 0px 0px 15px rgba($primaryColor, 0.5);
					width: 100%;
				}
			}

			.side-point {
				background-color: #faf6f8;
				padding: 10px;
				border-radius: 50px;
				flex: 1;

				h4 {
					font-size: 27px;
					margin-bottom: 10px;
				}
			}
		}
	}

	.not-included-container {
		margin-bottom: 120px;
		position: relative;

		.not-included-content {
			display: flex;
			align-items: flex-start;
		}

		.not-included {
			&:last-of-type {
				margin-left: 60px;
				flex-shrink: 0;
				background-color: #faf5f7;
				border-radius: 50px;
				border-bottom-right-radius: 300px;
				padding: 80px 100px;

				li {
					h4 {
						font-weight: 100;
						line-height: 19px;
					}
				}
			}

			.title {
				margin-bottom: 30px;
			}

			li {
				display: flex;
				margin-bottom: 30px;

				span {
					margin-right: 10px;
					background-color: rgba($primaryColor, 0.1);
					border-radius: 100%;
					color: $primaryColor;
					text-align: center;
					height: 17px;
					flex: 0 0 17px;
				}

				h4 {
					flex-shrink: 0;
					font-weight: 600;
					display: flex;
				}

				p {
					margin-top: 10px;
				}
			}
		}
	}

	.entering-program {
		.content {
			display: flex;
			align-items: center;

			img {
				box-shadow: 0px 0px 15px rgba($primaryColor, 0.5);
				border-radius: 20px;
				margin-right: 70px;
			}

			li {
				display: flex;
				align-items: flex-start;

				&:not(:last-of-type) {
					margin-bottom: 30px;
				}

				.bullet-point {
					margin-right: 10px;
					background-color: rgba($primaryColor, 0.1);
					border-radius: 100%;
					color: $primaryColor;
					text-align: center;
					height: 17px;
					flex: 0 0 17px;
				}

				p {
					line-height: 17px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1199px) {
	.services-page {
		.service {
			.img-container {
				border-bottom-left-radius: 20px !important;

				.details-container {
					padding: 10px !important;
				}
			}
		}
	}
}

@media only screen and (max-width: 950px) {
	.entering-program {
		.content {
			flex-direction: column;
			img {
				margin-right: 0 !important;
				margin-top: 50px;
				order: 1;
				max-width: 500px;
				width: 100%;
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	.not-included-container {
		.not-included-content {
			flex-direction: column;
			align-items: center !important;

			.not-included {
				margin-left: 0 !important;
			}
		}
	}
}

@media only screen and (max-width: 850px) {
	.services-page {
		.service {
			.image-panel {
				flex-direction: column;
			}
			.img-container {
				margin-right: auto !important;
				margin-left: auto;
				margin-top: 40px;
				width: 80%;
				order: 1;

				.details-container {
					justify-content: center !important;

					.detail {
						text-align: center !important;
					}
				}
			}

			.content {
				flex-direction: column;
				justify-content: center;

				.about-image {
					margin-top: 30px;
					width: 90%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}

@media only screen and (max-width: 530px) {
	.services-page {
		.service {
			&:nth-child(odd) {
				.img-container {
				}
			}

			.img-container {
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.services-page {
		.service {
			.img-container {
				width: 100%;
			}

			.content {
				padding: 20px;

				.about-image {
					width: 100%;
				}
			}
		}
	}
}
