@import '../../../sass/variables';

.home-page {
	.title {
		text-align: center;
		color: $primaryColor;
		text-transform: uppercase;
		margin-bottom: 50px;

		> *:nth-child(2) {
			margin-top: 10px;
		}

		h4 {
			font-size: 22px;
			font-weight: 100;
		}
		h3 {
			font-weight: 400;
			font-size: 27px;
		}
	}

	.core-value-container {
		position: relative;

		.bg-logo {
			right: -200px;
			top: -455px;
		}
	}
}

@media only screen and (min-width: $screen-md) {
	.home-page {
		.title {
			margin-bottom: 70px;

			h3 {
				font-size: 37px;
			}
			h4 {
				font-size: 28px;
			}
		}
	}
}

.home-page {
	.specialties {
		.single-specialty-wrapper {
			display: flex;
			justify-content: space-between;
			position: relative;

			// Mobile
			flex-direction: column;

			.single-specialty {
				color: $primaryColor;
				display: flex;
				align-items: center;
				flex-direction: column;
				position: relative;
				text-align: center;

				// Mobile
				flex-direction: row;
				justify-content: center;

				img {
					display: none;
				}

				h3 {
					font-weight: 200;
					font-size: 22px;
					line-height: 40px;
					margin-bottom: 13px;
				}

				p {
					font-size: 17px;
					font-weight: 100;
					line-height: 27px;
				}
			}

			.line {
				background-color: $primaryColor;
				height: 3px;
				width: 100px;
				margin: 40px auto;
			}
		}
	}
}

@media only screen and (min-width: $screen-md) {
	.home-page {
		.specialties {
			.single-specialty-wrapper {
				flex-direction: row;

				.single-specialty {
					flex-direction: column;
					justify-content: initial;
					width: 288px;

					&:not(:last-child) {
						&::before {
							content: '';
							background-color: $primaryColor;
							position: absolute;
							top: 23px;
							right: 0;
							height: 3px;
							width: 42%;
						}
					}

					&:not(:first-child) {
						&::after {
							content: '';
							background-color: $primaryColor;
							position: absolute;
							top: 23px;
							left: 0;
							height: 3px;
							width: 42%;
						}
					}

					img {
						$circleSize: 50px;
						display: initial;
						width: $circleSize;
						height: $circleSize;
					}

					h3 {
						width: 260px;
						margin: auto;
						margin-top: 40px;
						margin-bottom: 20px;
						font-size: 27px;
					}
				}

				.line {
					position: relative;
					flex: 1;
					top: 23px;
					height: 3px;
					width: initial;
					margin: initial;
				}
			}
		}
	}
}
.home-page {
	.about-content {
		color: $primaryColor;
		margin-bottom: 130px;
		display: flex;
		align-items: center;
		position: relative;

		img {
			border-radius: 20px;
			// border: 1px solid $primaryColor;
			box-shadow: 0px 0px 11px rgba($primaryColor, 0.5);
			position: absolute;
			left: -150px;
			width: 46%;
		}
		a:not(.button) {
			color: inherit;
			text-decoration: underline;
			font-size: 19px;

			&:hover {
				text-decoration: none;
			}
		}

		.text {
			width: 65%;
			margin-left: auto;

			.content {
				display: flex;
				flex-direction: column;
				align-items: center;

				p {
					font-weight: 100;
					font-size: 17px;
					line-height: 30px;
					text-align: center;
					margin-bottom: 40px;
				}

				a {
					display: inline-block;
				}
			}
		}
	}
}

@media only screen and (max-width: 1535px) {
	.home-page {
		.about-content {
			img {
				position: initial;
			}
			.text {
				width: 50%;
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.home-page {
		.about-content {
			img {
				width: 51%;
			}

			.text {
				width: 45%;
			}
		}
	}
}

@media only screen and (max-width: 1100px) {
	.home-page {
		.about-content {
			img {
				width: 54%;
			}

			.text {
				width: 40%;
			}
		}
	}
}

@media only screen and (max-width: $screen-md) {
	.home-page {
		.about-content {
			flex-direction: column;
			margin-bottom: 120px;

			img {
				margin-top: 50px;
				order: 1;
				width: 100%;
				max-width: 500px;
			}

			.text {
				width: 100%;

				p {
					margin-bottom: 20px !important;
				}
			}
		}
	}
}
