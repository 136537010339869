@import '../../../sass/variables';

.aca-page {
	color: $primaryColor;

	a,
	button {
		color: inherit;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	a {
		font-size: 19px;
	}

	button {
		cursor: pointer;
		padding: 0;
		font-size: inherit;
	}

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.about-aca {
		position: relative;
		margin-bottom: 100px;

		.bg-logo {
			top: -50px;
		}
		p {
			font-weight: 100;
			font-size: 17px;
			line-height: 30px;
		}
	}

	.bullet-point-list-container {
		display: flex;
		position: relative;
		font-weight: 200;

		.bullet-point-list {
			flex: 1;
			padding: 30px;

			&:first-of-type {
				background-color: rgb(250, 245, 247);
				margin-right: 30px;
				border-radius: 50px;
				border-top-right-radius: 300px;
			}

			.title {
				font-size: 23px;
				margin-bottom: 15px;
			}

			.subtitle {
				font-size: 15px;
				margin-bottom: 23px;
			}

			li {
				margin-bottom: 10px;
				line-height: 24px;
				display: flex;
				padding: 20px 0;

				span {
					margin-right: 10px;
					background-color: rgba($primaryColor, 0.1);
					border-radius: 100%;
					color: $primaryColor;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 27px;
					flex: 0 0 27px;
				}
			}
		}

		.bg-logo {
			bottom: -200px;
			right: -300px;
		}
	}
}

@media only screen and (max-width: 1200px) {
	.bullet-point-list-container .bullet-point-list:first-of-type {
		border-top-right-radius: 200px !important;
	}
}

@media only screen and (max-width: 930px) {
	.bullet-point-list-container {
		flex-direction: column;

		.bullet-point-list:first-of-type {
			margin-right: 0 !important;
		}
	}
}
