@import './variables';

footer {
	background-color: rgb(245, 229, 234);
	color: $primaryColor;
	position: relative;

	h3.title {
		font-size: 24px;
		margin-bottom: 15px;
	}

	.bg-img {
		background-image: url('/images/footer-img.png');
		background-size: contain;
		background-position: bottom;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.02;
	}

	.footer-content {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: 40px 0;
		padding-top: 80px;
		margin-top: 100px;
		position: relative;

		.contact {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			text-align-last: right;
			order: 1;

			.logo-link {
				position: absolute;
				top: -70px;
				left: 50%;
				transform: translateX(-50%);

				img {
					width: 110px;
					opacity: 0.8;
				}
			}

			div {
				margin-bottom: 5px;
			}

			a.contact-info {
				display: block;
				color: inherit;

				img {
					width: 15px;
					margin-right: 10px;
					opacity: 0.7;
				}

				.secondary-info {
					font-size: 13px;
					margin-top: 7px;
					opacity: 0.9;
				}
			}
		}

		.support {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 50px;

			.support-title {
				text-align: center;
				margin-bottom: 25px;

				h4 {
					font-weight: 400;
					font-size: 24px;
				}
				h3 {
					font-weight: 100;
					font-size: 15.4px;
					margin-top: 15px;
				}
			}

			.button {
				padding: 12px 40px;
			}
		}

		.about {
			display: flex;
			flex-direction: column;

			.info {
				width: 300px;
				line-height: 28px;
				font-weight: 200;
			}
		}
	}

	nav {
		padding-bottom: 10px;
		position: relative;

		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			li {
				margin: 0 20px;
			}

			a {
				color: inherit;
				font-size: 15px;
				padding: 10px 0;
				display: block;
				font-weight: 200;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

@media only screen and (min-width: 1075px) {
	footer {
		border-top-left-radius: 280px;

		.footer-content {
			flex-direction: row;
			padding-top: 130px;
			padding-bottom: 70px;
			margin-top: 150px;

			& > * {
				width: 370px;
			}

			.contact {
				justify-content: flex-start;
				order: initial;
				flex-direction: column;
				font-weight: 200;

				p {
					font-size: 17px;
					font-weight: 100;
					line-height: 24px;
				}

				a.contact-info {
					margin-top: 20px;
				}

				.logo-link {
					top: -95px;
					left: initial;
					transform: initial;

					img {
						width: 150px;
					}
				}
			}

			.support {
				margin-bottom: 25px;
				text-align: initial;

				.support-title {
					margin-bottom: 35px;

					h4 {
						font-size: 30px;
					}
					h3 {
						font-size: 15.2px;
					}
				}

				.button {
					padding: 15px 60px;
				}
			}

			nav {
				padding-top: 60px;

				ul {
					flex-direction: column;
					flex-wrap: inherit;
					justify-content: initial;

					&:not(:last-of-type) {
						margin-right: 70px;
					}

					li {
						margin: 5px 0;

						a {
							font-size: 15.5px;
						}
					}
				}
			}
		}
	}
}

.under-footer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	font-size: 12px;
	color: $primaryColor;
	padding: 20px 0;
	text-align: center;

	> div:first-of-type {
		margin-bottom: 15px;
	}

	> div:last-of-type {
		margin-top: 15px;
	}

	a {
		color: $primaryColor;
		text-decoration: underline;
	}
}

@media only screen and (min-width: $screen-md) {
	.under-footer {
		flex-direction: row;
		text-align: initial;
		padding: 15px 0;
		font-size: 13px;

		> div:first-of-type {
			margin-bottom: initial;
		}

		> div:last-of-type {
			margin-top: initial;
		}
	}
}
