@import '../../sass/variables';

.get-help {
	.recovery-options {
		display: flex;
		position: relative;

		// Mobile
		flex-direction: column;

		.recovery {
			background-color: white;
			color: $primaryColor;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: 0 0 20px 0px rgba(87, 27, 69, 0.2);
			border-radius: 20px;
			border: 0.5px solid rgba($primaryColor, 0.6);
			text-align: center;
			transition: background-color 0.2s ease;

			// Mobile
			padding: 80px 30px;

			&:hover {
				background-color: rgba($primaryColor, 0.08);
			}

			&:first-of-type {
				padding-top: 30px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: none;
			}

			&:last-of-type {
				padding-bottom: 30px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}

			.heading {
				font-size: 21px;
				line-height: 38px;
				font-weight: 100;
			}

			.content {
				font-weight: 100;
				font-size: 17px;
				line-height: 30px;
				margin-bottom: 30px;
			}

			.line {
				background-color: $primaryColor;
				opacity: 0.1;
				width: 70%;
				height: 1px;
				margin: 20px 0;
			}

			a {
				z-index: 1;
			}
		}

		.middle-or {
			position: relative;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			h3 {
				position: absolute;
				text-transform: uppercase;
				border-radius: 50%;
				border: 0.5px solid rgba($primaryColor, 0.6);
				background-color: white;
				color: $primaryColor;
				padding: 25px;
				box-shadow: 0 0 20px 0px rgba(87, 27, 69, 0.2);
			}
		}
	}
}

@media only screen and (min-width: $screen-md) {
	.get-help {
		.recovery-options {
			flex-direction: row;

			.recovery {
				padding: 50px 0 !important;
				flex: 1;

				&:first-of-type {
					border-top-right-radius: 0;
					border-bottom-left-radius: 20px;
					border-right: none;
					border-bottom: 1px solid $primaryColor;
				}

				&:last-of-type {
					border-bottom-left-radius: 0;
					border-top-right-radius: 20px;
				}

				.heading {
					font-size: 21px;
					line-height: 44px;
					padding: 0 20px;
					height: initial;
				}

				.content {
					height: 90px;
					padding: 0 70px;
					margin-bottom: initial;
				}

				.line {
					margin: 30px 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) and (min-width: $screen-md) {
	.get-help {
		.heading {
			height: 132px !important;
		}

		.content {
			height: 120px !important;
		}
	}
}
