@import '../../../sass/variables';

.staff {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 60px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.single-staff-container {
		&:nth-child(even) {
			text-align: right;

			.line,
			.description {
				margin-left: auto;
			}
		}

		h3 {
			font-size: 28px;
			font-weight: 200;
		}

		h4 {
			margin: 7px 0;
		}

		.line {
			height: 1px;
			width: 100px;
			background-color: $primaryColor;
			margin-top: 5px;
			margin-bottom: 20px;
		}

		.description {
			font-weight: 300;
			line-height: 24px;
			font-size: 15px;
			width: 80%;
			text-align: justify;
			opacity: 0.7;

			p {
				&:not(:last-of-type) {
					margin-bottom: 17px;
				}
			}
		}
	}

	.line-separator {
		height: 1px;
		width: 50%;
		background-color: rgba($primaryColor, 0.04);
		margin: 50px auto;
	}
}

@media only screen and (max-width: 1300px) {
	.staff {
		.description {
			width: 100% !important;
		}

		.bg-logo.right {
			display: none;
		}
	}
}

@media only screen and (max-width: 800px) {
	.staff {
		.single-staff-container {
			text-align: center !important;

			.line,
			.description {
				margin-right: auto;
				margin-left: auto;
			}
		}

		.line-separator {
			margin: 20px auto;
		}
	}
}
