@import '../../sass/variables';

.core-value-container {
	margin-bottom: 170px;

	.title {
		text-align: center;
		color: #571c45;
		text-transform: uppercase;
		margin-bottom: 70px;

		h2 {
			font-weight: 400;
			font-size: 35px;
		}
	}

	.key-component-container {
		display: flex;
		justify-content: space-between;

		.keypoint {
			display: flex;
			flex-direction: column;
			width: 23%;
			box-shadow: 0px 0px 20px rgba(black, 0.15);
			// background: linear-gradient(
			// 	to bottom right,
			// 	rgba($primaryColor, 0.15) 0%,
			// 	rgba($primaryColor, 0.02) 100%
			// );
			// background: linear-gradient(
			// 	to bottom right,
			// 	rgb(231, 220, 226) 0%,
			// 	rgb(251, 247, 248) 100%
			// );
			background-color: white;
			border: 1px solid rgba($primaryColor, 0.3);
			border-radius: 10px;
			padding: 30px 30px;
			flex: 1;
			z-index: 1;

			&:nth-of-type(2) {
				margin: 0 60px;
			}

			img {
				width: 90px;
				height: 90px;
			}

			.title {
				text-align-last: left;
				font-size: 24px;
				font-weight: 300;
				margin-top: 40px;
				margin-bottom: 20px;
			}

			p {
				font-weight: 100;
				font-size: 17px;
				line-height: 30px;
				color: $primaryColor;
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.core-value-container {
		.title {
			margin-bottom: 40px;
		}

		.key-component-container {
			display: flex;
			justify-content: space-between;

			.keypoint {
				&:nth-of-type(2) {
					margin: 0 30px;
				}

				.text .title {
					margin-bottom: 7px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	.core-value-container .key-component-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.keypoint:nth-of-type(2) {
			margin: 0;
			margin-left: 30px;
		}
	}
}

@media only screen and (max-width: 1000px) and (min-width: 500px) {
	.core-value-container .key-component-container .keypoint {
		&:nth-of-type(3) {
			flex: 100%;
			margin-top: 30px;
			flex-direction: row;

			.text {
				margin-left: 30px;

				.title {
					margin-bottom: 7px;
					margin-top: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 700px) and (min-width: 500px) {
	.core-value-container .key-component-container .keypoint {
		flex-direction: row;

		.text {
			margin-left: 30px;

			.title {
				margin-top: 0;
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.core-value-container {
		.title {
			margin-bottom: 0px;
		}

		.key-component-container .keypoint {
			flex: 100%;
			margin-top: 30px;
			margin-bottom: 7px;

			&:nth-of-type(2) {
				margin: 0;
				margin-left: 0;
				margin: 20px 0;
			}

			&:nth-of-type(3) {
				margin: 0;
			}

			.text .title {
				margin-bottom: 7px;
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.core-value-container {
		margin-bottom: 100px;
	}
}
