@charset "UTF-8";
.payments {
  color: #571c45; }
  .payments .diff-title {
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 50px; }
    .payments .diff-title h3 {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 35px;
      padding: 0 50px; }
    .payments .diff-title::before, .payments .diff-title::after {
      content: '';
      display: block;
      background-color: rgba(87, 28, 69, 0.25);
      flex: 1;
      height: 2px; }
  .payments .services {
    display: flex; }
    .payments .services .img-container {
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 0px 15px rgba(87, 28, 69, 0.5);
      flex: 1; }
      .payments .services .img-container:first-of-type {
        border-bottom-left-radius: 140px;
        text-align: right;
        margin-right: 50px; }
        .payments .services .img-container:first-of-type .details-container {
          justify-content: flex-end; }
      .payments .services .img-container:last-of-type {
        border-bottom-right-radius: 140px; }
      .payments .services .img-container img {
        width: 100%; }
      .payments .services .img-container h3.title {
        background-color: #ece3e7;
        padding: 20px;
        font-size: 25px;
        font-weight: 500; }
      .payments .services .img-container .details-container {
        display: flex;
        background-color: #ddcfd7;
        padding: 20px 50px; }
        .payments .services .img-container .details-container .detail {
          display: flex;
          flex-direction: column; }
          .payments .services .img-container .details-container .detail:nth-child(2) {
            margin: 0 40px;
            text-align: center; }
          .payments .services .img-container .details-container .detail h4 {
            font-size: 17px;
            margin-bottom: 5px; }
          .payments .services .img-container .details-container .detail span {
            font-size: 16px;
            font-weight: 100; }
  .payments .cost-breakdown {
    margin-bottom: 100px; }
    .payments .cost-breakdown .diff-title {
      margin-top: 100px; }
    .payments .cost-breakdown .payment-container {
      display: flex;
      justify-content: space-between; }
      .payments .cost-breakdown .payment-container .payment {
        flex-basis: 28%; }
      .payments .cost-breakdown .payment-container input {
        display: none; }
        .payments .cost-breakdown .payment-container input:checked + .cost-breakdown-label {
          background-color: rgba(87, 28, 69, 0.04);
          border: 1px solid #571c45;
          background-color: rgba(87, 28, 69, 0.04); }
          .payments .cost-breakdown .payment-container input:checked + .cost-breakdown-label .radio-container {
            background-color: #571c45;
            color: white; }
      .payments .cost-breakdown .payment-container .cost-breakdown-label {
        border-radius: 20px;
        box-shadow: 0px 0px 15px rgba(87, 28, 69, 0.5);
        padding: 30px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        user-select: none;
        border: 1px sold white; }
        .payments .cost-breakdown .payment-container .cost-breakdown-label .radio-container {
          border-radius: 100%;
          border: 1px solid #571c45;
          width: 40px;
          height: 40px;
          margin-bottom: 35px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .radio-container span {
            font-size: 18px; }
        .payments .cost-breakdown .payment-container .cost-breakdown-label .text {
          width: 100%; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text.expand .payment-plan {
            max-height: 150px !important; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text.expand img {
            transform: rotate(-180deg) !important; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text h3.title {
            font-size: 23px; }
            .payments .cost-breakdown .payment-container .cost-breakdown-label .text h3.title span {
              font-weight: 100;
              display: block;
              margin-top: 5px; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .total-price h4 {
            font-size: 20px;
            color: rgba(87, 27, 69, 0.7);
            margin-bottom: 10px; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .details-container {
            display: flex;
            justify-content: space-between; }
            .payments .cost-breakdown .payment-container .cost-breakdown-label .text .details-container .detail:last-of-type {
              text-align: right; }
            .payments .cost-breakdown .payment-container .cost-breakdown-label .text .details-container h5 {
              font-size: 16px;
              margin-bottom: 5px; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .line {
            background-color: rgba(87, 28, 69, 0.1);
            height: 1px;
            width: 100%;
            margin: 30px 0; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container input:checked + label .checkbox::before {
            content: '✓'; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container label {
            display: flex;
            align-items: center;
            cursor: pointer; }
            .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container label .checkbox {
              border: 1px solid #571c45;
              border-radius: 2px;
              height: 17px;
              flex-basis: 17px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container img {
            width: 14px;
            margin-left: 7px;
            transform: rotate(0deg);
            transition: transform 0.2s ease; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container .payment-plan {
            margin-top: 20px;
            max-height: 0;
            transition: max-height 0.2s ease;
            overflow: hidden;
            font-weight: 200; }
            .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container .payment-plan li {
              display: flex;
              padding: 10px 0;
              font-size: 15px; }
              .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container .payment-plan li span {
                margin-right: 10px;
                background-color: rgba(87, 28, 69, 0.1);
                border-radius: 100%;
                color: #571c45;
                text-align: center;
                height: 17px;
                flex: 0 0 17px; }
          .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container button {
            display: flex;
            cursor: pointer;
            padding: 0; }
            .payments .cost-breakdown .payment-container .cost-breakdown-label .text .payment-option-container button h5 {
              font-size: 14px;
              color: #571c45;
              line-height: 13px; }
  .payments .terms-section {
    margin-top: 100px;
    display: flex;
    font-weight: 200; }
    .payments .terms-section ul:first-of-type {
      margin-right: 100px; }
    .payments .terms-section li {
      display: flex;
      margin-bottom: 30px;
      line-height: 25px; }
      .payments .terms-section li span {
        margin-right: 10px;
        background-color: rgba(87, 28, 69, 0.1);
        border-radius: 100%;
        color: #571c45;
        text-align: center;
        height: 17px;
        flex: 0 0 17px;
        margin-top: 5px; }
  .payments .processing-fee {
    text-align: center;
    margin-top: 20px; }
  .payments .payment-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 50px;
    background-color: #faf5f7;
    box-shadow: 0px 0px 15px rgba(87, 28, 69, 0.15);
    padding: 40px 80px;
    border-radius: 20px;
    border-bottom-right-radius: 50px;
    width: 500px; }
    .payments .payment-review h3 {
      font-size: 23px;
      margin-bottom: 30px; }
    .payments .payment-review .payment-section {
      display: flex;
      justify-content: center;
      width: 100%; }
      .payments .payment-review .payment-section .payment {
        text-align: right;
        font-weight: 200; }
        .payments .payment-review .payment-section .payment:first-of-type {
          margin-right: 40px;
          text-align-last: left; }
        .payments .payment-review .payment-section .payment h4 {
          font-size: 19px;
          margin-bottom: 5px; }

@media only screen and (max-width: 1200px) {
  .payments .cost-breakdown .payment-container .payment {
    flex-basis: 31%; }
  .payments .services .details-container {
    height: 100%; }
  .payments .services .img-container:first-of-type {
    border-bottom-left-radius: 100px; }
  .payments .services .img-container:last-of-type {
    border-bottom-right-radius: 100px; } }

@media only screen and (max-width: 1150px) {
  .payments .cost-breakdown .payment-container .payment {
    flex-basis: 32%; } }

@media only screen and (max-width: 1050px) {
  .payments .cost-breakdown .payment-container {
    flex-wrap: wrap;
    justify-content: space-around; }
    .payments .cost-breakdown .payment-container .payment {
      flex-basis: 40%; }
      .payments .cost-breakdown .payment-container .payment:last-of-type {
        margin-top: 50px; } }

@media only screen and (max-width: 950px) {
  .payments .services {
    flex-direction: column; }
    .payments .services .img-container {
      margin-right: 0 !important;
      max-width: 500px; }
      .payments .services .img-container:last-of-type {
        margin-left: auto;
        margin-top: 50px; } }

@media only screen and (max-width: 850px) {
  .payments .cost-breakdown .payment-container .payment {
    flex-basis: 45%; } }

@media only screen and (max-width: 750px) {
  .payments .cost-breakdown .payment-container {
    align-items: center; }
    .payments .cost-breakdown .payment-container .payment {
      flex-basis: 60%; }
      .payments .cost-breakdown .payment-container .payment:first-of-type {
        margin-bottom: 50px; } }

@media only screen and (max-width: 600px) {
  .payments .cost-breakdown .payment-container .payment {
    flex-basis: 80%; }
  .payments .payment-info-container .terms-section {
    flex-direction: column;
    margin-top: 70px; } }

@media only screen and (max-width: 550px) {
  .payments .services .img-container:first-of-type {
    border-bottom-left-radius: 20px; }
  .payments .services .img-container:last-of-type {
    border-bottom-right-radius: 20px; }
  .payments .services .img-container h3.title {
    text-align: center !important; }
  .payments .services .img-container .details-container {
    padding: 30px;
    justify-content: space-between !important; }
    .payments .services .img-container .details-container .detail {
      margin: 0 !important;
      text-align: center;
      order: initial !important; }
  .payment-review {
    padding: 0 !important;
    width: 100% !important;
    height: 200px; } }

@media only screen and (max-width: 450px) {
  .payments .cost-breakdown .payment-container .payment {
    flex-basis: 100%; }
  .payments .services .img-container .details-container {
    padding: 20px 10px; }
    .payments .services .img-container .details-container .detail:nth-child(2) {
      margin: 0 10px !important; } }
