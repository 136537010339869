@import './Fonts/stylesheet.css';

@import url('https://fonts.googleapis.com/css?family=Fira+Sans:200,300,400,500,600,700,800,900&display=swap');

body {
	margin: 0;
	padding: 0;
	font-family: 'Fira Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #fffcfd;
}

* {
	box-sizing: border-box;
}
