@import '../../../sass/variables';

.schedule-page {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.schedule-summary-container {
		display: flex;
		justify-content: center;
		margin-bottom: 70px;

		.schedule-summary-inner-container {
			max-width: 500px;

			.summary-main-title {
				text-align: center;
				font-size: 25px;
				margin-bottom: 20px;
			}

			ul {
				display: flex;
				flex-direction: column;

				li {
					margin-bottom: 30px;

					&:nth-child(even) {
						margin-left: auto;

						.schedule-title {
							text-align: right;
						}

						.content {
							text-align: right;
						}
					}

					.schedule-title {
						font-size: 30px;
						opacity: 0.2;


						span {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	.table-container {
		box-shadow: 0px 0px 11px rgba($primaryColor, 0.3);
		border-radius: 20px;
		overflow: auto;
	}
	table {
		border-spacing: 0;
		min-width: 900px;
		overflow: auto;

		thead {
			background-color: rgba($primaryColor, 0.7);
			color: white;

			th {
				padding: 30px 0;

				&:nth-child(1) {
					width: 9%;
				}

				&:nth-child(8) {
					width: 12%;
				}

				h4 {
					letter-spacing: 5px;
					font-size: 17px;
					font-weight: 800;
				}
			}
		}

		tbody {
			tr {
				td,
				th {
					padding: 20px 5px;
				}

				&:nth-child(even) {
					background-color: rgba($primaryColor, 0.06);
				}

				td {
					font-size: 13px;
					line-height: 18px;
					font-weight: 200;
					text-align: center;
				}
				th span,
				td span {
					font-size: 11px;
					font-weight: 100;
					display: block;
				}
			}
		}
	}

	.notes {
		margin-top: 40px;
		margin-left: 10px;

		.title {
			font-weight: 500;
			margin-bottom: 10px;
		}
		.single-note {
			font-size: 12px;
			margin-bottom: 7px;
			margin-left: 15px;
		}
	}

	.disclaimer {
		font-size: 12px;
		margin-top: 50px;
	}
}
