@import '../../../sass/variables';

.stats-page {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.amudim-stats {
		margin-bottom: 100px;

		p {
			font-size: 19px;
			line-height: 40px;

			span {
				font-weight: 600;
				font-size: 21px;
				color: rgba($primaryColor, 0.9);
			}
		}
	}

	.stats-overall-container {
		.diff-title {
			margin-bottom: 90px;
			margin-top: 40px;
		}

		.stats-container {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			position: relative;

			&.center {
				justify-content: center;
			}

			.bg-logo {
				&.right {
					right: -200px;
				}

				&.first {
					top: -500px;
				}

				&.third {
					top: -200px;
				}
			}

			.stat {
				flex-basis: 24%;
				background-color: white;
				border: 1px solid rgba($primaryColor, 0.05);
				box-shadow: 0px 0px 5px rgba($primaryColor, 0.1);
				border-radius: 20px;
				margin: 0 6px;
				margin-bottom: 75px;
				padding: 25px;
				position: relative;

				.stat-title {
					font-size: 25px;
					color: rgba($primaryColor, 0.7);
				}

				.line {
					width: 100%;
					height: 1px;
					background-color: rgba($primaryColor, 0.1);
					margin: 15px 0;
				}

				.stat-desc {
					color: rgba($primaryColor, 0.6);
					line-height: 25px;

					a {
						text-decoration: underline;
						color: inherit;

						&:hover {
							color: $primaryColor;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.reference-container {
		margin-top: 60px;

		h4 {
			margin-bottom: 13px;
			font-size: 19px;
		}

		li {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			span {
				margin-right: 10px;
				background-color: rgba($primaryColor, 0.1);
				border-radius: 100%;
				color: $primaryColor;
				text-align: center;
				height: 11px;
				flex: 0 0 11px;
			}

			p {
				font-size: 14px;

				a {
					text-decoration: underline;
					color: rgba($primaryColor, 0.7);

					&:hover {
						color: $primaryColor;
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.stats-page {
		.stat {
			flex-basis: 30% !important;
			margin-bottom: 40px !important;
		}

		.reference-container {
			li {
				align-items: flex-start;

				span {
					margin-top: 3px;
				}
			}
		}
	}
}

@media only screen and (max-width: 950px) {
	.stats-page {
		.stats-overall-container {
			.diff-title {
				margin-bottom: 40px;
				margin-top: 20px;

				h3 {
					padding: 0 20px;
				}
			}
		}

		.stat {
			flex-basis: 45% !important;
		}
	}
}

@media only screen and (max-width: 700px) {
	.stats-page {
		.amudim-stats {
			.diff-title {
				margin-bottom: 10px;

				h3 {
					padding: 0 20px;
				}
			}

			p {
				line-height: 33px;
				text-align: center;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.stats-page {
		.stat {
			flex-basis: 100% !important;
			margin-bottom: 20px !important;
		}
	}
}
