@import '../../../sass/variables';

.stats-individual-page {
	color: $primaryColor;

	.main-title {
		text-align: center;
		margin-bottom: 15px;
	}

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.paragraph-text {
		font-weight: 200;
		line-height: 30px;
		margin-bottom: 50px;
	}

	.stats-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;

		.stat {
			flex-basis: 24%;
			background-color: white;
			border: 1px solid rgba($primaryColor, 0.05);
			box-shadow: 0px 0px 5px rgba($primaryColor, 0.1);
			border-radius: 20px;
			margin: 0 6px;
			margin-bottom: 75px;
			padding: 25px;
			position: relative;

			.stat-title {
				font-size: 25px;
				color: rgba($primaryColor, 0.7);
			}

			.line {
				width: 100%;
				height: 1px;
				background-color: rgba($primaryColor, 0.1);
				margin: 15px 0;
			}

			.stat-desc {
				color: rgba($primaryColor, 0.6);
				line-height: 25px;

				a {
					text-decoration: underline;
					color: inherit;

					&:hover {
						color: $primaryColor;
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.stats-individual-page {
		.stat {
			flex-basis: 30% !important;
			margin-bottom: 40px !important;
		}
	}
}

@media only screen and (max-width: 950px) {
	.stats-individual-page {
		.stat {
			flex-basis: 45% !important;
		}
	}
}

@media only screen and (max-width: 600px) {
	.stats-individual-page {
		.stat {
			flex-basis: 100% !important;
			margin-bottom: 20px !important;
		}
	}
}
