@import './variables';

.top-header {
	position: fixed;
	top: 0;
	z-index: 1600;
	width: 100%;
	background-color: white;
	box-shadow: 0px 0px 7px rgba(87, 28, 69, 0.2);

	.top-line {
		background-color: $primaryColor;
		height: 5px;
		width: 100%;
	}

	// .info {
	// 	display: flex;
	// 	// align-items: flex-end;
	// 	flex-direction: column;
	// 	margin: 25px 0;

	// 	#call-text {
	// 		color: #818181;
	// 		font-size: 17px;
	// 	}

	// 	#number {
	// 		color: #6e6e6e;
	// 		font-size: 21px;
	// 	}
	// }
}
