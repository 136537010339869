@import '../../sass/variables';

.payment-info-container {
	.payment-info {
		display: flex;

		.info-section {
			flex: 1;

			&:first-of-type {
				margin-right: 100px;
			}

			.title {
				display: flex;
				align-items: center;

				span {
					margin-right: 12px;
					border-radius: 100%;
					background-color: #faf5f7;
					box-shadow: 0 0 5px 0px rgba($primaryColor, 0.4);
					width: 45px;
					height: 45px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.container {
				display: flex;
				margin-top: 10px;

				.textfield-container {
					display: flex;
					align-items: flex-end;
					flex: 1;

					&:not(:last-of-type) {
						margin-right: 30px;
					}

					.MuiTextField-root {
						flex: 1;
						margin-left: 10px;
					}

					.MuiFormHelperText-root {
						position: absolute;
						bottom: -16px;
						font-size: 10px;
						color: #bf0000;
					}

					.MuiFormLabel-root.Mui-error {
						color: #bf0000;
					}
				}
			}
		}

		.MuiInput-underline:after {
			border-bottom: 2px solid $primaryColor;
		}

		.MuiFormLabel-root.Mui-focused {
			color: $primaryColor;
		}
	}

	.error {
		margin-top: 10px;

		a {
			color: $primaryColor;
		}
	}

	.card-container {
		position: relative;
		display: flex;
		margin-top: 70px;

		.credit-bg {
			background-color: #efe8ec;
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			position: absolute;
			height: 100%;
			transform: translate(30px, 20px);
			width: 290px;
			z-index: -1;
		}
	}

	.submit-section {
		margin-top: 70px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.bg-logo {
			top: -320px;
			right: -300px;
		}

		.diff-title {
			text-align: center;
			display: flex;
			align-items: center;
			width: 100%;

			button {
				cursor: pointer;
				padding: 18px 100px;
				margin: 0 50px;
				padding: initial !important;
				height: 60px;
				width: 200px;
				transition-property: width, border-radius, background-color,
					border;
				transition-duration: 0.3s;
				transition-timing-function: ease;

				&.load {
					width: 60px;
					border-radius: 100%;
					background-color: white;
					border: none;
					cursor: default;

					.MuiCircularProgress-circle {
						stroke: $primaryColor;
					}
				}
			}

			&::before,
			&::after {
				content: '';
				display: block;
				background-color: rgba($primaryColor, 0.25);
				flex: 1;
				height: 2px;
			}
		}
	}
}

@media only screen and (max-width: 1050px) {
	.payment-info-container .payment-info .info-section .container {
		flex-direction: column;

		.textfield-container {
			margin-right: 0 !important;
		}
	}
}

@media only screen and (max-width: 750px) {
	.payment-info-container .terms-section ul:first-of-type {
		margin-right: 50px !important;
	}
}

@media only screen and (max-width: 650px) {
	.payment-info-container .payment-info {
		flex-direction: column;

		.info-section:first-of-type {
			margin-right: 0;
			margin-bottom: 50px;
		}

		.card-container {
			margin-top: 30px;
		}
	}
}
