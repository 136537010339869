@import '../../../sass/variables';

.why-treatment {
	color: $primaryColor;
	position: relative;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.bold {
		font-weight: 400;
	}

	.bg-logo {
		top: -100px;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.button {
			margin-top: 30px;
		}
	}

	p {
		font-weight: 100;
		font-size: 17px;
		line-height: 30px;
	}

	.do-i-have-addiction {
		margin-top: 100px;
	}

	.therapy-is-necessary {
		margin-bottom: 50px;
		align-items: center;
		text-align: center;
	}

	.image-text-container {
		.image-text {
			display: flex;
			margin-bottom: 100px;

			&:nth-child(even) {
				img {
					order: 1;
					margin-right: 0 !important;
					margin-left: 50px;
				}
			}

			img {
				margin-right: 50px;
				box-shadow: 0px 0px 15px rgba($primaryColor, 0.5);
				border-radius: 60px;
				width: 500px;
			}
		}
	}

	.stick-out {
		margin-bottom: 100px;
		background-color: $primaryColor;

		h4 {
			color: white;
			text-align: center;
			font-size: 20px;
			font-weight: 100;
			padding: 50px 0;
			line-height: 43px;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.image-text {
		flex-direction: column;
		align-items: center;

		img {
			width: 100%;
			max-width: 600px;
			order: 1;
			margin-top: 50px;
			margin-right: 0 !important;
			margin-left: 0 !important;
		}
	}
}
