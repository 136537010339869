@import '../../../sass/variables';

.stats-and-definitions {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.amudim-stats {
		margin-bottom: 70px;
		font-weight: 200;

		p {
			font-size: 19px;
			line-height: 40px;

			span {
				font-weight: 400;
				font-size: 18px;
				color: rgba($primaryColor, 0.9);
			}
		}
	}

	.quote-container {
		margin: 100px 0;
	}

	.reference-container {
		margin-top: 60px;

		h4 {
			margin-bottom: 13px;
			font-size: 19px;
		}

		li {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			span {
				margin-right: 10px;
				background-color: rgba($primaryColor, 0.1);
				border-radius: 100%;
				color: $primaryColor;
				text-align: center;
				height: 11px;
				flex: 0 0 11px;
			}

			p {
				font-size: 14px;

				a {
					text-decoration: underline;
					color: rgba($primaryColor, 0.7);

					&:hover {
						color: $primaryColor;
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.stats-and-definitions {
		.reference-container {
			li {
				align-items: flex-start;

				span {
					margin-top: 3px;
				}
			}
		}
	}
}

@media only screen and (max-width: 950px) {
	.stats-and-definitions {
		.stats-overall-container {
			.diff-title {
				margin-bottom: 40px;
				margin-top: 20px;

				h3 {
					padding: 0 20px;
				}
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.stats-and-definitions {
		.amudim-stats {
			.diff-title {
				margin-bottom: 10px;

				h3 {
					padding: 0 20px;
				}
			}

			p {
				line-height: 33px;
				text-align: center;
			}
		}
	}
}
