@font-face {
    font-family: 'Garamond';
    src: url('Garamond-Italic.woff2') format('woff2'),
        url('Garamond-Italic.woff') format('woff'),
        url('Garamond-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Garamond';
    src: url('Garamond-Bold.woff2') format('woff2'),
        url('Garamond-Bold.woff') format('woff'),
        url('Garamond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Garamond';
    src: url('Garamond.woff2') format('woff2'),
        url('Garamond.woff') format('woff'),
        url('Garamond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('BebasNeueBook.woff2') format('woff2'),
        url('BebasNeueBook.woff') format('woff'),
        url('BebasNeueBook.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueLight.woff2') format('woff2'),
        url('BebasNeueLight.woff') format('woff'),
        url('BebasNeueLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueBold.woff2') format('woff2'),
        url('BebasNeueBold.woff') format('woff'),
        url('BebasNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

