@import './variables';

body,
html {
	overflow-x: hidden;
}

body {
	margin-top: 90px;
}

@media only screen and (min-width: 1075px) {
	body {
		margin-top: 100px;
	}
}

.default-width {
	width: 90%;
	margin-left: auto !important;
	margin-right: auto !important;
}

.screen-xl {
	display: none;
}

.screen-m {
	display: block;
}

// $screen-sm: 576px;

@media only screen and (min-width: $screen-md) {
	// 768px
	.default-width {
		width: 90%;
	}
}

@media only screen and (min-width: $screen-lg) {
	// 992px
	.default-width {
		width: 90%;
	}
}

@media only screen and (min-width: 1400px) {
	// 992px
	.default-width {
		width: 1200px;
	}
}

@media only screen and (min-width: $screen-lg) {
	.screen-xl {
		display: block;
	}

	.screen-m {
		display: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Bebas Neue Book';
	letter-spacing: 3px;
}

.bg-logo {
	position: absolute;
	left: -200px;
	background-image: url('/images/logoBG.png');
	background-size: 100%;
	background-repeat: no-repeat;
	width: 800px;
	height: 710px;
	z-index: -5;
	opacity: 0.025;

	&.right {
		left: initial;
		right: -100px;
	}
}

.MuiTooltip-tooltip {
	background-color: #f6f2f4 !important;
	// border: 1px solid rgba($primaryColor, 0.2) !important;
	box-shadow: 0px 0px 7px rgba($primaryColor, 0.3) !important;
	border-radius: 20px !important;
	padding: 15px !important;
	font-size: 12px !important;
	line-height: 22px !important;
	color: $primaryColor !important;
	font-weight: 300 !important;
	margin: 0 !important;

	a {
		color: $primaryColor !important;
		text-decoration: underline;
	}
}

.MuiButton-root {
	background-color: rgba($primaryColor, 0.08) !important;
	padding: 0px 4px !important;
	min-width: initial !important;
	color: $primaryColor !important;
	font-weight: inherit !important;

	&:after {
		content: '';
		position: absolute;
		top: 22px;
		left: 0;
		right: 0;
		border-bottom: 1px dotted $primaryColor;
		transition: border-bottom 0.3s ease;
	}
}

.underline {
	text-decoration: underline;
}

.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;

	&:after {
		content: '';
		position: absolute;
		top: 26px;
		left: 0;
		right: 0;
		border-bottom: 1px dotted $primaryColor;
		transition: border-bottom 0.3s ease;
	}

	&:hover {
		&:after {
			border-bottom: 1px dotted transparent;
		}

		.tooltip-text {
			opacity: 1;
			transform: translateY(0);
			z-index: 1;
		}
	}

	.tooltip-text {
		background-color: white;
		border: 2px solid rgba($primaryColor, 0.2);
		border-radius: 20px;
		position: absolute;
		top: 40px;
		left: -50px;
		width: 400px;
		padding: 15px;
		font-size: 14px;
		line-height: 24px;

		opacity: 0;
		transform: translateY(30px);
		transform-origin: top;
		z-index: -4;
		transition: transform 0.2s ease, opacity 0.2s ease;

		&:after,
		&:before {
			content: '';
			position: absolute;
			top: -24px;
			left: 55px;
			border-width: 12px;
			border-style: solid;
			border-color: transparent transparent white transparent;
		}

		&:before {
			top: -32px;
			left: 51px;
			border-width: 16px;
			border-color: transparent transparent rgba($primaryColor, 0.2)
				transparent;
		}

		a {
			font-size: 14px !important;
		}
	}
}

@media only screen and (max-width: 800px) {
	.bg-logo {
		width: 570px;
		height: 490px;
	}
}

@media only screen and (max-width: 500px) {
	.bg-logo {
		width: 400px;
		height: 344px;
	}

	.tooltip .tooltip-text {
		width: 100%;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
	/* CSS specific to iOS devices */
	body {
		cursor: pointer;
	}
}
