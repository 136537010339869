@import './variables';

.image-header {
	height: 170px;
	margin-bottom: 40px;
	z-index: 30;

	&.home {
		height: 300px;

		.bg-image {
			background-position-x: 40%;
		}
	}

	.content-wrapper {
		position: relative;
		height: 100%;

		.img-overlay {
			// background-color: rgba($color: white, $alpha: 0.8);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.bg-image {
			height: 100%;
			background-size: cover;
			background-position-x: 70%;
			background-position-y: bottom;
		}

		.content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			color: $primaryColor;
			display: flex;

			.header-title {
				width: 100%;
				font-weight: 600;

				&.home-content {
					display: flex;
					justify-content: space-evenly;
					flex-direction: column;
					align-items: center;
					height: 80%;
					position: initial;

					h3 {
						font-size: 24px;
						font-weight: 100;
						text-align: center;
					}

					h2 {
						font-size: 26.4px;
						text-transform: uppercase;
						margin-top: 15px;
					}
				}

				.filler {
					width: 100%;
					padding-bottom: 4px;
					margin-top: 50px;
				}

				&:not(.home-content) {
					h3.title {
						font-size: 33px;
						text-transform: uppercase;
						font-weight: 400;
						text-align: center;

						&:after {
							content: '';
							position: absolute;
							top: 0;
							right: 100%;
							width: 100%;
							margin-top: 100px;
						}
					}
				}
			}

			.button {
				font-weight: 100;
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	.image-header {
		.header-title {
			text-align: initial;
		}
	}
}

@media only screen and (min-width: 530px) {
	.image-header {
		height: 40vh;
		min-height: 340px;
		max-height: 600px;
		margin-top: initial;
		margin-bottom: 40px;

		&.home {
			min-height: 530px;
			max-height: 600px;
		}

		.content-wrapper {
			.content {
				align-items: flex-start;

				.header-title {
					width: initial;

					&:not(.home-content) {
						h3.title {
							font-size: 50px;
						}
					}

					&.home-content {
						align-items: flex-start;

						.bg-image {
							background-position-x: 40%;
						}

						h3 {
							font-size: 29.2px;
							font-weight: 100;
							text-align: initial;
						}
						h2 {
							font-size: 46.5px;
							font-weight: 100;
						}
					}

					.filler {
						margin-top: 100px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1530px) {
	.image-header {
		min-height: 440px;
	}
}
