@import '../../../sass/variables';

.definitions-individual-page {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;
		margin-bottom: 50px;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}
	}

	.definition-container {
		display: flex;
		margin-bottom: 100px;

		.title {
			margin-right: 35px;
			margin-top: 4px;
			font-size: 34px;
			flex-shrink: 0;
			max-width: 230px;
			line-height: 45px;
		}

		.description-container {
			font-weight: 200;

			p {
				margin-bottom: 25px;
				line-height: 30px;
			}

			ol {
				padding-left: 30px;
				text-align: left;

				li {
					margin-bottom: 10px;
					line-height: 30px;

					span {
						margin-right: 15px;
						width: 15px;
						display: inline-block;
						text-align: right;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.definitions-individual-page {
		.diff-title {
			margin-bottom: 30px;
		}

		.definition-container {
			margin-bottom: 30px;

			.title {
				font-size: 28px;
				max-width: 200px;
				margin-right: 23px;
			}

			ol {
				padding-left: 0 !important;
			}
		}
	}
}

@media only screen and (max-width: 580px) {
	.definitions-individual-page {
		.definition-container {
			flex-direction: column;
			margin-right: 0;
			text-align: center;

			.title {
				max-width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
	}
}
