@import '../../sass/variables';

@mixin regBtn {
	background-color: $primaryColor;
	border: 1px solid white;
	color: white !important;
}

@mixin invertedBtn {
	background-color: white;
	border: 1px solid $primaryColor;
	color: $primaryColor !important;
}

.button {
	border-radius: $borderRadius;
	padding: 17px 29px;
	font-size: 17px;
	font-family: 'Bebas Neue Book';
	letter-spacing: 3px;

	@include regBtn();

	&:hover {
		@include invertedBtn();
	}

	&.inverted {
		@include invertedBtn();

		&:hover {
			@include regBtn();
		}
	}
}

@media only screen and (min-width: $screen-md) {
	.button {
		padding: 20px 38px;
		font-size: 20px;
	}
}
