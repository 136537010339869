@import '../../../sass/variables';

.donate-page {
	color: $primaryColor;

	.diff-title {
		text-align: center;
		display: flex;
		align-items: center;

		h3 {
			text-transform: uppercase;
			font-weight: 100;
			font-size: 35px;
			padding: 0 50px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			background-color: rgba($primaryColor, 0.25);
			flex: 1;
			height: 2px;
		}

		.bg-logo {
			top: 300px;
		}
	}

	.sub-title {
		text-align: center;
		margin-bottom: 70px;
		margin-top: 30px;
		font-weight: 100;
		font-size: 21px;
		line-height: 30px;
	}

	.donate-card-container {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		margin-bottom: 50px;

		.donate-card {
			flex-basis: 43%;

			.donate-input {
				display: none;

				&:checked + .donate-wrapper {
					border: 1px solid #571c45;
					background-color: #f8f3f6;

					.checkmark {
						opacity: 1;
						transform: scale(1);
					}
				}
			}

			.textfield {
				width: 50px;
				padding: 5px 7px;
				border: none;
				outline: none;
				position: relative;
				top: -2px;
				font-size: 16px;
				border-radius: 6px;
				background-color: rgba($primaryColor, 0.03);
				border: 0.5px solid rgba($primaryColor, 0.23);

				&.custom {
					width: 100px;
				}
			}

			.donate-wrapper {
				display: flex;
				flex-direction: column;
				cursor: pointer;
				border: 1px solid rgba($primaryColor, 0.06);
				box-shadow: 0px 0px 11px rgba($primaryColor, 0.3);
				border-radius: 30px;
				padding: 20px 30px;
				margin-bottom: 50px;
				background-color: white;

				.donate-top {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					margin-bottom: 30px;

					.checkmark {
						width: 36px;
						flex-basis: 36px;
						height: 36px;
						border-radius: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #7c4b6d;
						box-shadow: 0px 0px 11px rgba($primaryColor, 0.3);
						opacity: 0;
						transform: scale(0.3);
						transition: opacity 0.2s ease, transform 0.2s ease;

						span {
							font-size: 20px;
							color: white;
						}
					}
					.amount {
						font-size: 20px;
						display: flex;
						align-items: center;

						.line {
							font-weight: 100;
							margin: 0 5px;
						}
						p {
							font-weight: 100;
							font-size: 17px;
						}
					}
				}

				.description {
					line-height: 25px;
					font-weight: 100;
				}

				.montlyOptIn {
					margin-top: 10px;
				}

				.MuiFormControl-root {
					width: 35px;
					margin-top: -5px;
				}

				.donate {
					.MuiFormControl-root {
						width: 100px;
					}
				}

				.MuiInput-underline:before,
				.MuiInput-underline:after {
					bottom: 4px;
				}

				.spacer-divide {
					height: 36px;
				}

				.montly-opt-in {
					display: flex;
					align-items: center;
					margin-top: 20px;

					input {
						display: none;

						&:checked + label .checkbox span {
							display: block;
						}
					}

					label {
						display: flex;
						width: 244px;
						cursor: pointer;
						user-select: none;

						.checkbox {
							border: 1px solid #571c45;
							border-radius: 2px;
							height: 17px;
							flex-basis: 17px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 10px;

							span {
								display: none;
								margin-top: 2px;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.donate-page {
		.donate-card {
			flex-basis: 48% !important;
		}
	}
}

@media only screen and (max-width: 1069px) {
	.donate-page {
		.donate-card:nth-child(3) {
			.spacer-multiply {
				height: 25px;
			}
		}
	}
}

@media only screen and (max-width: 948px) {
	.donate-page {
		.spacer-divide {
			height: 13px !important;
		}
	}
}

@media only screen and (max-width: 745px) {
	.donate-page {
		.donate-card:nth-child(3) {
			.spacer-multiply {
				height: 50px;
			}
		}
	}
}

@media only screen and (max-width: 733px) {
	.donate-page {
		.spacer-divide {
			height: 38px !important;
		}
	}
}

@media only screen and (max-width: 675px) {
	.donate-page {
		.donate-wrapper {
			margin-bottom: 30px !important;
		}

		.donate-card {
			flex-basis: 100% !important;

			.spacer-divide {
				height: 0 !important;
			}
			.spacer-multiply {
				height: 0 !important;
			}
		}
	}
}

@media only screen and (max-width: 675px) {
	.donate-page {
		.diff-title {
			justify-content: center;

			h3 {
				font-size: 30px;
				padding: 0;
			}

			&::before,
			&::after {
				display: none;
			}
		}
	}
}
