@import './variables';

.top-header {
	// Mobile Navbar

	font-family: 'Bebas Neue Book';
	letter-spacing: 3px;

	.screen-m-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 0;

		.left {
			display: flex;
			align-items: center;
		}

		.hamburger-menu {
			display: flex;
			flex-direction: column;

			span {
				height: 2px;
				width: 27px;
				background-color: #571c45;
				display: inline-block;

				&:not(:last-of-type) {
					margin-bottom: 5px;
				}
			}
		}

		.logo-link {
			img {
				width: 80px;
			}
		}

		.button {
			padding: 9px 16px;
			font-size: 14px;
			margin-left: 20px;
		}

		nav {
			position: absolute;

			.nav-bg {
				background-color: rgba($color: black, $alpha: 0.7);
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 999;

				&.nav-bg-animate-enter {
					opacity: 0.01;

					&.nav-bg-animate-enter-active {
						opacity: 1;
						transition: opacity 200ms ease-in-out;
					}
				}

				&.nav-bg-animate-leave {
					opacity: 1;

					&.nav-bg-animate-leave-active {
						transition: opacity 200ms ease-in-out;
						opacity: 0.01;
					}
				}
			}

			.close-menu {
				margin-left: 23px;
				margin-top: 41px;
				z-index: 100;

				span {
					display: block;
					width: 22px;
					height: 2px;
					background-color: rgba($color: white, $alpha: 0.75);

					&:first-of-type {
						transform: rotate(45deg) translateY(1px);
					}
					&:last-of-type {
						transform: rotate(-45deg) translateY(-1px);
					}
				}
			}

			.menu-wrapper {
				background-color: $primaryColor;
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				z-index: 1000;
				transform: translateX(-100%);
				transition: transform 200ms ease-in-out;

				&.open {
					transform: translateX(0);
					overflow: auto;
				}

				ul {
					padding-left: 50px;
					padding-right: 36px;
					margin-top: 50px;
				}

				li {
					margin: 33px 0;

					a {
						color: rgba($color: white, $alpha: 0.75);
						letter-spacing: 2px;
						font-size: 22px;
						padding: 10px 0;
					}
				}
			}
		}
	}

	// Desktop navbar
	nav.screen-xl-inner {
		display: flex;
		justify-content: center;
		position: relative;
		align-items: center;

		.inner-nav {
			display: flex;
			justify-content: space-between;
			flex-basis: 100%;
		}

		ul {
			display: flex;
			align-items: center;

			.menu-item {
				margin-right: 30px;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				cursor: pointer !important;

				&:after {
					content: '';
					position: absolute;
					top: 65px;
					height: 1.5px;
					width: 100%;
					transform: scaleX(0);
					transform-origin: left;
					background-color: $primaryColor;
					transition: transform 200ms ease;
				}

				&:hover {
					&:after {
						transform: scaleX(1);
					}

					.sub-menu {
						transform: scaleY(1);
						opacity: 1;
					}
				}

				.sub-menu {
					position: absolute;
					top: 95px;
					left: 0;
					margin-left: -20px;
					display: flex;
					flex-direction: column;
					background-color: #fefcfd;
					box-shadow: 0px 11px 9px rgba($primaryColor, 0.09);
					border-radius: 10px;
					border-top-right-radius: 0;
					border-top-left-radius: 0;
					z-index: 100;
					width: 250px;
					transform: scaleY(0);
					opacity: 0;
					transform-origin: top;
					transition: transform 200ms ease, opacity 200ms ease;

					li {
						width: 100%;

						&:first-of-type {
							margin-top: 15px;
						}

						&:last-of-type {
							margin-bottom: 10px;
						}

						a {
							width: 100%;
							padding: 17px 15px;
							cursor: pointer;
							font-size: 17px;
							display: block;

							&:hover {
								background-color: rgba($primaryColor, 0.1);
							}
						}
					}
				}
			}

			a {
				color: $primaryColor;
				font-size: 22px;
				display: block;
				text-transform: uppercase;
			}
		}

		.right-nav {
			display: flex;
			align-items: center;

			.button {
				padding: 12px 35px;
				margin-right: 35px;
			}

			.logo-link {
				padding: 5px 0;

				img {
					width: 90px;
				}
			}
		}
	}
}
