@import '../../../sass/variables';

.contact-page {
	display: flex;
	flex-direction: column;

	h1.title {
		text-align: center;
		font-size: 40px;
		font-weight: 100;
		color: $primaryColor;
	}

	.contact-inner-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 30px;

		.contact-form {
			flex: 1;

			.intro-text {
				color: $primaryColor;
				line-height: 29px;
				letter-spacing: 1px;
				margin-bottom: 30px;
				text-align: center;
				font-weight: 200;
			}

			form {
				display: flex;
				flex-direction: column;

				.top-input-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				input,
				textarea {
					font-size: 20px;
					padding: 15px 14px;
					outline: none;
					background-color: rgba($color: $primaryColor, $alpha: 0.05);
					border: none;
					border-bottom: 1.7px solid
						rgba($color: $primaryColor, $alpha: 0.7);
					color: $primaryColor;
					border-radius: $borderRadius;
					letter-spacing: 1px;
					font-weight: 300;

					&::placeholder {
						color: rgba($color: black, $alpha: 0.2);
					}

					&:focus {
						background-color: rgba(
							$color: $primaryColor,
							$alpha: 0.1
						);
					}
				}

				.input-container {
					flex-basis: 32%;

					&:not(:last-of-type) {
						margin-bottom: 17px;
					}

					input {
						width: 100%;
					}
				}
				textarea {
					width: 100%;
					margin-top: 17px;
					resize: none;
				}

				.txt-error {
					font-size: 11px;
					margin-top: 4px;
					color: hsla(0, 100%, 20%, 1);
				}

				.submit-container {
					position: relative;
					margin-top: 20px;

					.send-btn {
						cursor: pointer;
						padding: 18px 60px;
						text-transform: uppercase;
						font-size: 22px;
						font-family: 'Bebas Neue Book';
						margin-left: auto;
						display: block;
						width: 165px;
						background-color: rgba(
							$color: $primaryColor,
							$alpha: 0.05
						);
						letter-spacing: 3px;
						color: $primaryColor;
						border-radius: $borderRadius;
						border-bottom: 1.7px solid
							rgba($color: $primaryColor, $alpha: 0.7);

						transition: all 0.2s ease;

						&.Send:hover {
							background-color: rgba(
								$color: $primaryColor,
								$alpha: 0.9
							);
							color: white;
						}

						&:not(.Send) {
							width: 60px;
							height: 60px;
							padding: 0;
							border-radius: 100%;
							background-color: white;
							border: none;
							cursor: default;
						}
					}

					.MuiCircularProgress-root {
						position: absolute;
						top: 0;
						right: 0;

						.MuiCircularProgress-circle {
							stroke: $primaryColor;
						}
					}
				}
			}

			.submit-msg {
				color: $primaryColor;
				text-align: right;
				margin-top: 40px;
			}
		}

		.content-info-container {
			color: $primaryColor;
			margin-top: 50px;

			h3.title {
				font-size: 26px;
				text-align: center;
				font-weight: 400;
				margin-bottom: 30px;
			}

			.contact-info {
				display: flex;
				flex-direction: column;

				.single-contact-wrapper {
					display: flex;
					align-items: center;
					flex-direction: column;
					border: 0.3px solid rgba($color: $primaryColor, $alpha: 0.5);
					border-right: none;
					border-left: none;
					padding: 25px 0;

					&:first-of-type {
						border-bottom: none;
					}

					.icon-wrapper {
						width: 60px;
						height: 60px;
						border: 1px solid $primaryColor;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-bottom: 13px;
						transform: scale(1);
						transition: transform 0.2s ease;

						&:hover {
							transform: scale(1.05);
						}

						img {
							width: 23px;
						}
					}

					.info {
						font-weight: 200;
						color: $primaryColor;
						font-size: 20px;
					}

					.secondary {
						color: $primaryColor;
						font-weight: 200;
						font-size: 14px;
						margin-top: 8px;
						opacity: 0.9;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $screen-md) {
	.contact-page {
		.contact-inner-wrapper {
			flex-direction: initial;
			margin-top: 50px;

			.contact-form {
				margin-right: 50px;

				.intro-text {
					text-align: initial;
				}

				form {
					.top-input-wrapper {
						flex-direction: initial;
					}

					.submit-container {
						margin-top: 30px;

						.send-btn {
							// width: initial;
						}
					}

					input {
						&:not(:last-of-type) {
							margin-right: 17px;
							margin-bottom: initial;
						}
					}
				}
			}

			.content-info-container {
				margin-left: 40px;
				margin-top: initial;

				h3.title {
					font-size: 30px;
					text-align: initial;
				}
			}
		}
	}
}
